var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sec-one-right"}},[(_vm.GETSECTYPE == 'secureComplex')?_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Как вы хотите использовать тревожную кнопку? "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[5];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid buttons-grid-no-grid"},[_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.securityButtonType == 'phisical'
        },on:{"click":function($event){_vm.rightOne.securityButtonType = 'phisical';
          _vm.setChanges();}}},[_vm._v(" ФИЗИЧЕСКОЕ ОБОРУДОВАНИЕ ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.securityButtonType == 'app'
        },on:{"click":function($event){_vm.rightOne.securityButtonType = 'app';
          _vm.setChanges();}}},[_vm._v(" МОБИЛЬНОЕ ПРИЛОЖЕНИЕ ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.securityButtonType == 'both'
        },on:{"click":function($event){_vm.rightOne.securityButtonType = 'both';
          _vm.setChanges();}}},[_vm._v(" ВСЕ ВЫШЕПЕРЕЧИСЛЕННОЕ ")])])]):_vm._e(),(_vm.GETSECTYPE == 'secureSignal')?_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Сколько у Вас помещений с окнами? "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[4];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid"},[_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.rightOne.windowsQuantity == 1 },on:{"click":function($event){_vm.rightOne.windowsQuantity = 1;
          _vm.setChanges();}}},[_vm._v(" 1 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.rightOne.windowsQuantity == 2 },on:{"click":function($event){_vm.rightOne.windowsQuantity = 2;
          _vm.setChanges();}}},[_vm._v(" 2 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.rightOne.windowsQuantity == 3 },on:{"click":function($event){_vm.rightOne.windowsQuantity = 3;
          _vm.setChanges();}}},[_vm._v(" 3 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.rightOne.windowsQuantity == 4 },on:{"click":function($event){_vm.rightOne.windowsQuantity = 4;
          _vm.setChanges();}}},[_vm._v(" 4 ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rightOne.windowsQuantity),expression:"rightOne.windowsQuantity"}],staticClass:"question-input",attrs:{"type":"text","placeholder":"Впишите количесвто входов на объекте"},domProps:{"value":(_vm.rightOne.windowsQuantity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.rightOne, "windowsQuantity", $event.target.value)},function($event){return _vm.setChanges()}]}})]):_vm._e(),(_vm.GETBUTTONTYPE == 'both' || _vm.GETBUTTONTYPE == 'phisical')?_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Укажите количество брелков с тревожной кнопкой "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[6];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid"},[_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.controlPanelsQuantity == 1
        },on:{"click":function($event){_vm.rightOne.controlPanelsQuantity = 1;
          _vm.setChanges();}}},[_vm._v(" 1 ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.controlPanelsQuantity == 2
        },on:{"click":function($event){_vm.rightOne.controlPanelsQuantity = 2;
          _vm.setChanges();}}},[_vm._v(" 2 ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.controlPanelsQuantity == 3
        },on:{"click":function($event){_vm.rightOne.controlPanelsQuantity = 3;
          _vm.setChanges();}}},[_vm._v(" 3 ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.controlPanelsQuantity == 4
        },on:{"click":function($event){_vm.rightOne.controlPanelsQuantity = 4;
          _vm.setChanges();}}},[_vm._v(" 4 ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rightOne.controlPanelsQuantity),expression:"rightOne.controlPanelsQuantity"}],staticClass:"question-input",attrs:{"type":"text","placeholder":"Впишите количесвто входов на объекте"},domProps:{"value":(_vm.rightOne.controlPanelsQuantity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.rightOne, "controlPanelsQuantity", $event.target.value)},function($event){return _vm.setChanges()}]}})]):_vm._e(),(_vm.GETBUTTONTYPE == 'both' || _vm.GETBUTTONTYPE == 'phisical')?_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Укажите количество стационарных тревожных кнопок "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[7];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid"},[_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.stationarPanelsQuantity == 1
        },on:{"click":function($event){_vm.rightOne.stationarPanelsQuantity = 1;
          _vm.setChanges();}}},[_vm._v(" 1 ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.stationarPanelsQuantity == 2
        },on:{"click":function($event){_vm.rightOne.stationarPanelsQuantity = 2;
          _vm.setChanges();}}},[_vm._v(" 2 ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.stationarPanelsQuantity == 3
        },on:{"click":function($event){_vm.rightOne.stationarPanelsQuantity = 3;
          _vm.setChanges();}}},[_vm._v(" 3 ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.rightOne.stationarPanelsQuantity == 4
        },on:{"click":function($event){_vm.rightOne.stationarPanelsQuantity = 4;
          _vm.setChanges();}}},[_vm._v(" 4 ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rightOne.stationarPanelsQuantity),expression:"rightOne.stationarPanelsQuantity"}],staticClass:"question-input",attrs:{"type":"text","placeholder":"Впишите количесвто входов на объекте"},domProps:{"value":(_vm.rightOne.stationarPanelsQuantity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.rightOne, "stationarPanelsQuantity", $event.target.value)},function($event){return _vm.setChanges()}]}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.GETISFIRSTPAGEREADY),expression:"GETISFIRSTPAGEREADY"}],staticClass:"next-button",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" ДАЛЕЕ ")]),_c('secInfoPopup',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoPopup),expression:"infoPopup"}],attrs:{"text":_vm.text},on:{"close":function($event){_vm.infoPopup = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }