<template>
  <div id="sec-one-right">
    <!--//////////////////////////////////////////////////////////////////////////////////////////ок-->

    <div class="question-block" v-if="GETSECTYPE == 'secureComplex'">
      <div class="question-text">
        Как вы хотите использовать тревожную кнопку?
        <span
          class="info"
          @click="
            text = GETTEXT[5];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid buttons-grid-no-grid">
        <div
          class="button"
          :class="{
            'active-select-button': rightOne.securityButtonType == 'phisical'
          }"
          @click="
            rightOne.securityButtonType = 'phisical';
            setChanges();
          "
        >
          ФИЗИЧЕСКОЕ ОБОРУДОВАНИЕ
        </div>
        <div
          class="button"
          :class="{
            'active-select-button': rightOne.securityButtonType == 'app'
          }"
          @click="
            rightOne.securityButtonType = 'app';
            setChanges();
          "
        >
          МОБИЛЬНОЕ ПРИЛОЖЕНИЕ
        </div>

        <div
          class="button"
          :class="{
            'active-select-button': rightOne.securityButtonType == 'both'
          }"
          @click="
            rightOne.securityButtonType = 'both';
            setChanges();
          "
        >
          ВСЕ ВЫШЕПЕРЕЧИСЛЕННОЕ
        </div>
      </div>
    </div>

    <!--//////////////////////////////////////////////////////////////////////////////////////////-->

    <div class="question-block" v-if="GETSECTYPE == 'secureSignal'">
      <div class="question-text">
        Сколько у Вас помещений с окнами?
        <span
          class="info"
          @click="
            text = GETTEXT[4];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid">
        <div
          class="button"
          @click="
            rightOne.windowsQuantity = 1;
            setChanges();
          "
          :class="{ 'active-select-button': rightOne.windowsQuantity == 1 }"
        >
          1
        </div>
        <div
          class="button"
          @click="
            rightOne.windowsQuantity = 2;
            setChanges();
          "
          :class="{ 'active-select-button': rightOne.windowsQuantity == 2 }"
        >
          2
        </div>
        <div
          class="button"
          @click="
            rightOne.windowsQuantity = 3;
            setChanges();
          "
          :class="{ 'active-select-button': rightOne.windowsQuantity == 3 }"
        >
          3
        </div>
        <div
          class="button"
          @click="
            rightOne.windowsQuantity = 4;
            setChanges();
          "
          :class="{ 'active-select-button': rightOne.windowsQuantity == 4 }"
        >
          4
        </div>
      </div>
      <input
        v-model="rightOne.windowsQuantity"
        type="text"
        class="question-input"
        placeholder="Впишите количесвто входов на объекте"
        @input="setChanges()"
      />
    </div>

    <!--//////////////////////////////////////////////////////////////////////////////////////////-->

    <div
      class="question-block"
      v-if="GETBUTTONTYPE == 'both' || GETBUTTONTYPE == 'phisical'"
    >
      <div class="question-text">
        Укажите количество брелков с тревожной кнопкой
        <span
          class="info"
          @click="
            text = GETTEXT[6];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid">
        <div
          class="button"
          @click="
            rightOne.controlPanelsQuantity = 1;
            setChanges();
          "
          :class="{
            'active-select-button': rightOne.controlPanelsQuantity == 1
          }"
        >
          1
        </div>
        <div
          class="button"
          @click="
            rightOne.controlPanelsQuantity = 2;
            setChanges();
          "
          :class="{
            'active-select-button': rightOne.controlPanelsQuantity == 2
          }"
        >
          2
        </div>
        <div
          class="button"
          @click="
            rightOne.controlPanelsQuantity = 3;
            setChanges();
          "
          :class="{
            'active-select-button': rightOne.controlPanelsQuantity == 3
          }"
        >
          3
        </div>
        <div
          class="button"
          @click="
            rightOne.controlPanelsQuantity = 4;
            setChanges();
          "
          :class="{
            'active-select-button': rightOne.controlPanelsQuantity == 4
          }"
        >
          4
        </div>
      </div>
      <input
        v-model="rightOne.controlPanelsQuantity"
        type="text"
        class="question-input"
        placeholder="Впишите количесвто входов на объекте"
        @input="setChanges()"
      />
    </div>

    <!--//////////////////////////////////////////////////////////////////////////////////////////-->

    <div
      class="question-block"
      v-if="GETBUTTONTYPE == 'both' || GETBUTTONTYPE == 'phisical'"
    >
      <div class="question-text">
        Укажите количество стационарных тревожных кнопок
        <span
          class="info"
          @click="
            text = GETTEXT[7];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid">
        <div
          class="button"
          @click="
            rightOne.stationarPanelsQuantity = 1;
            setChanges();
          "
          :class="{
            'active-select-button': rightOne.stationarPanelsQuantity == 1
          }"
        >
          1
        </div>
        <div
          class="button"
          @click="
            rightOne.stationarPanelsQuantity = 2;
            setChanges();
          "
          :class="{
            'active-select-button': rightOne.stationarPanelsQuantity == 2
          }"
        >
          2
        </div>
        <div
          class="button"
          @click="
            rightOne.stationarPanelsQuantity = 3;
            setChanges();
          "
          :class="{
            'active-select-button': rightOne.stationarPanelsQuantity == 3
          }"
        >
          3
        </div>
        <div
          class="button"
          @click="
            rightOne.stationarPanelsQuantity = 4;
            setChanges();
          "
          :class="{
            'active-select-button': rightOne.stationarPanelsQuantity == 4
          }"
        >
          4
        </div>
      </div>
      <input
        v-model="rightOne.stationarPanelsQuantity"
        type="text"
        class="question-input"
        placeholder="Впишите количесвто входов на объекте"
        @input="setChanges()"
      />
    </div>

    <!--//////////////////////////////////////////////////////////////////////////////////////////-->
    <div class="next-button" v-show="GETISFIRSTPAGEREADY" @click="nextPage()">
      ДАЛЕЕ
    </div>

    <secInfoPopup v-show="infoPopup" :text="text" @close="infoPopup = false" />
  </div>
</template>

<script>
import secInfoPopup from "@/components/secInfoPopup.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "sec-one-right",
  components: {
    secInfoPopup
  },
  data() {
    return {
      rightOne: {
        entriesQuantity: null,
        securityButtonType: null,
        windowsQuantity: null,
        controlPanelsQuantity: null,
        stationarPanelsQuantity: null
      },
      infoPopup: false,
      text: null
    };
  },
  computed: {
    ...mapGetters([
      "GETISFIRSTPAGEREADY",
      "GETTEXT",
      "GETSECTYPE",
      "GETBUTTONTYPE"
    ])
  },
  methods: {
    ...mapMutations(["SETONERIGHTPERCENT", "SETDATA"]),
    setChanges() {
      let i = 0;

      if (this.GETSECTYPE == "secureSignal") {
        if (
          this.rightOne.windowsQuantity != null &&
          this.rightOne.windowsQuantity != 0
        ) {
          i += 100;
        }
      }
      if (this.GETSECTYPE == "secureButton") {
        if (this.GETBUTTONTYPE == "app") {
          i = 100;
        } else {
          if (
            this.rightOne.stationarPanelsQuantity != null &&
            this.rightOne.stationarPanelsQuantity != 0
          ) {
            i += 50;
          }
          if (
            this.rightOne.controlPanelsQuantity != null &&
            this.rightOne.controlPanelsQuantity != 0
          ) {
            i += 50;
          }
        }
      }

      if (this.GETSECTYPE == "secureComplex") {
        if (this.rightOne.securityButtonType != null) {
          i += 34;
        }
        if (
          this.rightOne.stationarPanelsQuantity != null &&
          this.rightOne.stationarPanelsQuantity != 0
        ) {
          i += 33;
        }
        if (
          this.rightOne.controlPanelsQuantity != null &&
          this.rightOne.controlPanelsQuantity != 0
        ) {
          i += 33;
        }
        if (this.rightOne.securityButtonType == "app") {
          i = 100;
        }
      }

      this.SETONERIGHTPERCENT(i);
      this.SETDATA({ name: "oneRight", data: this.rightOne });
    },
    nextPage() {
      this.$emit("next");
    }
  },
  watch: {
    GETSECTYPE: function() {
      this.setChanges();
    },
    GETBUTTONTYPE: function() {
      this.setChanges();
    }
  }
};
</script>

<style lang="scss">
#sec-one-right {
  .question-block {
    margin-bottom: 17px;
    .question-text {
      margin-bottom: 13px;
      width: 98%;
      margin-left: auto;
      margin-right: auto;

      .info {
        @include info;
      }
    }

    .buttons-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: 15px;

      .button {
        @include button-main;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .buttons-grid-two {
      width: 98%;
      margin-left: auto;
      margin-right: auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: auto;

      .button {
        margin-left: 0%;
        margin-right: 0;
      }

      .button-right {
        margin-left: auto;
        margin-right: 0;
      }
    }

    .buttons-grid-no-grid {
      display: block;

      .button {
        margin-top: 15px;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .question-input {
      @include input-main;
      width: 98%;
    }
  }

  .next-button {
    @include button-main;
    width: 180px;
    background: linear-gradient(
      90deg,
      rgba(33, 71, 211, 0.5) 0%,
      rgba(91, 179, 149, 0.5) 100%
    );
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
}
</style>
